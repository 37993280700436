import React from "react"

import { TopBar } from "@launchacademy/voyager"
import logoImage from "@launchacademy/voyager/dist/images/logo.svg"
import { graphql, Link } from "gatsby"
import _ from "lodash"

import "./css/guide-layout.css"
import "../../css/richContent.css"
import Seo from "../../components/seo"

const BootcampGuideShow = ({ data, location }) => {
  const logo = (
    <Link to="/">
      <img src={logoImage} className="navigation-list__logo" alt="Launch Academy" />
    </Link>
  )
  const { guidePage, allPages } = data
  const guidePages = allPages.nodes.sort(
    (firstPage, secondPage) =>
      parseInt(firstPage.chapterNumber, 10) - parseInt(secondPage.chapterNumber, 10),
  )

  const pageLinks = guidePages.map((page) => {
    return (
      <li key={page.id} className="bootcamp-guide__page">
        <Link activeClassName="active" to={`/bootcamp-guide/${page.slug}/`}>
          {page.title}
        </Link>
      </li>
    )
  })
  const navLinks = guidePages.map((page) => {
    return (
      <li key={`nav-${page.id}`} className="navigation-list__link">
        <Link activeClassName="active" to={`/bootcamp-guide/${page.slug}/`}>
          {page.title}
        </Link>
      </li>
    )
  })
  return (
    <>
      <Seo
        title={guidePage.title}
        bodyClassName="bootcamp-guide-body"
        description={
          guidePage.excerpt ||
          _.truncate(guidePage.content.data.content.replace(/(<([^>]+)>)/gi, ""), { length: 130 })
        }
      />
      <TopBar logo={logo} logoAltText="Launch Academy" logoUrl="/">
        <ul className="navigation-list navigation-list__top" role="list">
          {navLinks}
        </ul>
      </TopBar>
      <div className="main-content">
        <div className="container bootcamp-guide">
          <nav className="bootcamp-guide__page-listing">
            <ol className="bootcamp-guide__page-list">{pageLinks}</ol>
          </nav>
          <article className="bootcamp-guide__page-content">
            <h2 className="bootcamp-guide__page-chapter-number">
              Chapter {guidePage.chapterNumber}
            </h2>
            <h1 className="bootcamp-guide__page-title">{guidePage.title}</h1>
            <div
              className="rich-content"
              dangerouslySetInnerHTML={{ __html: guidePage.content.data.content }}
            />
          </article>
        </div>
      </div>
      <div id="portal"></div>
    </>
  )
}

export const pageQuery = graphql`
  query GuideShowPage($slug: String!) {
    guidePage: strapiBootcampGuidePage(slug: { eq: $slug }) {
      chapterNumber
      content {
        data {
          content
        }
      }
      slug
      title
    }
    allPages: allStrapiBootcampGuidePage(sort: { fields: chapterNumber, order: ASC }) {
      nodes {
        chapterNumber
        slug
        id
        title
      }
    }
  }
`

export default BootcampGuideShow
